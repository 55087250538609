import React from "react"
import { isMobile } from "react-device-detect"
const isClient = typeof window !== "undefined"
import { makeDeeplinkLink } from "@services/utils"

interface IFirebaseLink {
  redirect_url: string
}

export default function FirebaseLink(props: IFirebaseLink) {
  let firebase_redirect = "/"

  if (
    isMobile &&
    typeof props.redirect_url === "string" &&
    props.redirect_url.indexOf("http") === 0
  ) {
    firebase_redirect = makeDeeplinkLink(props.redirect_url)
  }

  if (isClient) {
    window.location.href = firebase_redirect
  }

  return firebase_redirect !== "/" ? (
    <div>
      <p>Will be redirected.</p>
      <p>
        Click <a href={firebase_redirect}>here</a> if not redirected.
      </p>
    </div>
  ) : (
    <p>Invalid URL</p>
  )
}
